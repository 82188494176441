import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from 'react';

import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import AOS from 'aos';

import Header from '../Header';
import Footer from '../Footer';
import ThemeSwitch from '../ThemeSwitch';

import ModalVideo from '../ModalVideo';
import ContactModal from '../ContactModal';
import AboutModal from '../AboutModal';

import GlobalContext from '../../context/GlobalContext';

import GlobalStyle from '../../utils/globalStyle';

import favicon from '../../assets/brandkit/compressed/favicons/favicon.svg';

import '../../assets/fonts/icon-font/fonts/avasta.ttf';
import '../../assets/fonts/icon-font/fonts/Grayic.ttf';
import '../../assets/fonts/icon-font/css/style.css';

import './bootstrap-custom.scss';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../node_modules/aos/dist/aos.css';

import ogImage from '../../assets/brandkit/compressed/social/vedus-social-share-fb_1200x630.png';
import twitterImage from '../../assets/brandkit/compressed/social/vedus-social-share-twitter_1200x628.png';

import { get, merge } from 'lodash';

// the full theme object
import { theme as baseTheme } from '../../utils';

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

// options for different color modes
const modes = { light: 'light', dark: 'dark' };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext, pageTitle, layoutType }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init();
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    function handlePopState() {
      gContext.closeAbout();
      gContext.closeContact();
    }

    function handlePushState() {
      gContext.closeAbout();
      gContext.closeContact();
    }

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('pushState', handlePushState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('pushState', handlePushState);
    };
  }, [gContext]);

  const commonHelmet = (
    <Helmet>
      <html lang="en" />
      <title>{`Vedus | ${pageTitle}`}</title>
      <link rel="icon" type="image/svg" href={favicon} />
      <meta name="theme-color" content="#485fef" />

      <meta
        name="description"
        content="Vedus: Experts in Software Engineering, IT Consulting, and System Design. Custom software without limits. Services in Web & Mobile Apps, UX/UI, Blockchain, AI & more. Ready to start your project? Contact Vedus today."
      />
      <meta
        name="keywords"
        content="Software Engineering, IT Consulting, System Design, Web & Mobile Apps, UX/UI, Blockchain, AI, Vedus"
      />

      {/* Metas for Open Graph (Facebook, LinkedIn) */}
      <meta property="og:title" content={`Vedus | ${pageTitle}`} />
      <meta
        property="og:description"
        content="Vedus: Experts in Software Engineering, IT Consulting, and System Design."
      />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content="https://vedus.io" />

      {/* Metas for Twitter */}
      <meta name="twitter:title" content={`Vedus | ${pageTitle}`} />
      <meta
        name="twitter:description"
        content="Vedus: Experts in Software Engineering, IT Consulting, and System Design."
      />
      <meta name="twitter:image" content={twitterImage} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );

  if (pageContext?.layout === 'bare' || layoutType === 'bare') {
    return (
      <ThemeProvider
        theme={
          gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <ThemeSwitch />
        <GlobalStyle />
        {commonHelmet}
        <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>

        <ModalVideo />
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.theme.bodyDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <ThemeSwitch />
        <GlobalStyle />
        {commonHelmet}
        <Loader id="loading" className={visibleLoader ? '' : 'inActive'}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header isDark={gContext.theme.headerDark} />
          {children}

          <Footer isDark={gContext.theme.footerDark} />
        </div>
        <AboutModal />
        <ContactModal />
        <ModalVideo />
      </ThemeProvider>
    </>
  );
};

export default Layout;
