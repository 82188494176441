import React from 'react';
import { Link } from 'gatsby';
import { Image } from 'react-bootstrap';
import logo from '../../assets/brandkit/compressed/logos/vedus-horizontal-1800x477-light.svg';

const LogoLight = ({ color = 'front', height, className = '', ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
      <Image src={logo} alt="Vedus Logo" width={'150px'} height={'auto'} />
    </Link>
  );
};

export default LogoLight;
