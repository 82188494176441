import React from 'react';
import { Container } from 'react-bootstrap';
import { Box, Title } from '../Core';
import { FaLinkedin, FaInstagram, FaGithub, FaFacebook } from 'react-icons/fa';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledIconLink = styled.a`
  display: inline-block;
  margin-right: 10px;
  transition: color 0.3s ease;

  &:hover {
    color: currentColor !important;
    opacity: 0.9;
  }

  &:active {
    color: currentColor !important;
  }

  &:visited {
    color: currentColor !important;
  }

  & > svg {
    color: inherit;
  }
`;

const StyledTextLink = styled(Link)`
  font-size: 0.7rem;
  margin-right: 10px; // ajustez selon vos besoins
  transition: color 0.3s ease, opacity 0.3s ease;

  &:hover {
    color: currentColor !important;
    opacity: 0.9;
  }

  &:active {
    color: currentColor !important;
  }

  &:visited {
    color: currentColor !important;
  }
`;

// Tooltip "Coming Soon" style
const StyledTooltip = styled.span`
  display: none;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(73, 95, 239, 1);
  color: white;
  border: 0px solid;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.6rem;
  white-space: nowrap;
`;

const ComingSoonLink = styled(StyledTextLink)`
  position: relative;
  cursor: crosshair;

  &:hover ${StyledTooltip} {
    display: block;
  }
`;

const d = new Date();
let year = d.getFullYear();

const Footer = ({ isDark = true }) => {
  const handleLinkClick = (event) => {
    // Prevent default link behavior
    event.preventDefault();
  };
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box
        bg="bg"
        pt={[0, null, null, '3.75rem']}
        pb="3.75rem"
        className="position-relative"
      >
        <Container>
          <div className="text-center">
            <div>
              <StyledIconLink
                href="https://www.linkedin.com/company/veduslabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Vedus on LinkedIn"
              >
                <FaLinkedin />
              </StyledIconLink>
              <StyledIconLink
                href="https://www.instagram.com/veduslabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Vedus on Instagram"
              >
                <FaInstagram />
              </StyledIconLink>
              <StyledIconLink
                href="https://github.com/veduslabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Vedus on Github"
              >
                <FaGithub />
              </StyledIconLink>
              <StyledIconLink
                href="https://www.facebook.com/veduslabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Vedus on Facebook"
              >
                <FaFacebook />
              </StyledIconLink>
              <StyledIconLink
                href="https://twitter.com/veduslabs"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Vedus on X"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={{ marginRight: '10px', width: '1em', height: '1em' }}
                >
                  <path
                    fill="currentColor"
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                  />
                </svg>
              </StyledIconLink>
            </div>

            <Title
              variant="card"
              css={`
                font-size: 0.8125rem;
                margin-top: 1rem;
              `}
              className="text-uppercase"
            >
              © {year} Vedus - All rights reserved
            </Title>

            {/* <div style={{ fontSize: '0.7rem', marginTop: '0.5rem' }}>
              <div style={{ fontSize: '0.7rem', marginTop: '0.5rem' }}>
                <StyledTextLink to="/privacy">Privacy Policy</StyledTextLink>
                |&nbsp;&nbsp;&nbsp;
                <StyledTextLink to="/legal-notice">Legal Notice</StyledTextLink>
              </div>
            </div> */}

            <div style={{ fontSize: '0.7rem', marginTop: '0.5rem' }}>
              <ComingSoonLink
                // to="/privacy"
                href="#"
                rel="nofollow"
                onClick={handleLinkClick}
              >
                Privacy Policy
                <StyledTooltip>Coming Soon</StyledTooltip>
              </ComingSoonLink>
              |&nbsp;&nbsp;&nbsp;
              <ComingSoonLink
                // to="/legal-notice"
                href="#"
                rel="nofollow"
                onClick={handleLinkClick}
              >
                Legal Notice
                <StyledTooltip>Coming Soon</StyledTooltip>
              </ComingSoonLink>
            </div>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
